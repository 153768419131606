<template>
  <div class="orderlist">
    <!-- <div>code --- {{code}}</div>
    <div>openId --- {{openId}}</div>
    <div> 步进   ---- {{text}}</div> -->
    <div class="header">
      <div class="left">
        <van-icon name="arrow-left" @click="gopath('back')" class="icon" />
      </div>
      <div class="title">我的订单</div>
    </div>
    <van-tabs v-model="active" @change="changTab" swipeable>
      <van-tab v-for="(tab, index) in tablist" :key="index" :title="tab.title">
        <div class="list">
          <div
            class="item"
            @click="gopath('/orderinfo', cou)"
            v-for="(cou, ind) in list"
            :key="ind"
          >
            <div class="top">
              <div class="top_left">
                <img :src="cou.coverPath" class="left_img" />
              </div>
              <div class="top_right">
                <div class="right_title">{{ cou.orderName }}</div>
                <div class="term">
                  <span>有效期：</span> {{ cou.orderTime }}
                </div>
              </div>
            </div>
            <div class="center">
              <span class="total">总价: ¥{{ cou.totalPrice }}</span>
              <span class="actually">应付:¥{{ cou.actuallyPrice }}</span>
            </div>
            <div class="bottom">
              <div class="bottom_left">
                <span class="yell_text">赠</span>
                <span class="text_p">
                  {{
                    cou.giveCourseNames[0] ? cou.giveCourseNames[0] : "暂无赠课"
                  }}</span
                >
              </div>
              <div class="bottom_right">
                <van-button
                  type="default"
                  v-if="cou.payState == 0"
                  @click.stop="cancel(cou)"
                  >取消订单</van-button
                >
                <van-button
                  type="default"
                  v-if="cou.payState == 0"
                  class="payBtn"
                  @click.stop="pay(cou)"
                  >去支付</van-button
                >
                <div class="cancal" v-if="cou.payState == 2">已取消</div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <div class="alipaysubmit" v-html="alipayfrom"></div>
  </div>
</template>


<script>
import { orderList, cancalOrder, payOrder } from "@/api/user.js";
import { getOpenId } from "@/api/order.js";
function getUrlKey(name) {
  //获取url 参数
  return (
    decodeURIComponent(
      (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
        location.href
      ) || [, ""])[1].replace(/\+/g, "%20")
    ) || null
  );
}

function getCodeApi(state) {
  //获取code
  let urlNow = encodeURIComponent(window.location.href);
  let scope = "snsapi_base"; //snsapi_userinfo //静默授权 用户无感知
  //let appid = "wxaca9d08ae096883e";
  let appid = "wx7f4b3784986dcb47";
  let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${urlNow}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
  window.location.replace(url);
}
export default {
  components: {},
  data() {
    return {
      active: 0,
      page: 0,
      list: [],
      tablist: [
        { title: "全部", active: 0 },
        { title: "待支付", active: 1 },
        { title: "已支付", active: 2 },
      ],
      alipayfrom: null,
      openId: "",
      code: "空",
      text:''
    };
  },
  created() {
    if (window.location.href.indexOf("code") > -1) {
      if (this.isWeixin()) {
        this.text = '有code'
        let that = this;
        that.code = getUrlKey("code");
        if (that.code) {
          this.text = '有code'
          let data = new FormData();
          data.append("code", that.code);
          data.append(
            "userId",
            JSON.parse(window.localStorage.getItem("userInfo")).data.id
          );
          getOpenId(data).then((res) => {
            that.$set(that, "openId", res.data.data.openId);
            // that.openId =  res.data.openId;
          });
        }
      }
    } else {
      if (this.isWeixin()) {
        this.text = '微信'
        getCodeApi(123);
      }
    }
    
  },
  mounted() {
    this.orderList();
  },
  methods: {
    isWeixin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (
        ua.match(/MicroMessenger/i) == "micromessenger" ||
        ua.match(/_SQ_/i) == "_sq_"
      ) {
        return true;
      } else {
        return false;
      }
    },
    // 取消订单
    cancel(val) {
      this.$dialog
        .confirm({
          message: "确认取消？",
        })
        .then(() => {
          let formData = new FormData();
          formData.append("orderId", val.orderId);
          cancalOrder(formData).then((res) => {
            if (res.data.code == 0) {
              this.$toast("取消成功");
              this.orderList();
            } else {
              this.$toast(res.data.msg);
            }
          });
        })
        .catch(() => {
          return;
        });
    },
    // 支付
    pay(val) {
      window.sessionStorage.setItem("payFlag", "2");
      let formData = new FormData();
      formData.append("orderId", val.orderId);
      if (this.isWeixin()) {
        formData.append("payWeChatType", 1);
        formData.append("openid", this.openId);
      } else {
        formData.append("payWeChatType", 2);
      }
      payOrder(formData).then((res) => {
        if (res.data.code == 0) {
          window.sessionStorage.setItem("payFlag", "1");
          window.sessionStorage.setItem("orderId", val.orderId);
          window.sessionStorage.setItem("orderType", val.orderType);
          if (res.data.data.payWay == "微信") {
            if (this.isWeixin()) {
              if(res.data.data.nonce_str) {
                this.$toast("请在微信内部支付！");
                return
              }
              WeixinJSBridge.invoke(
                "getBrandWCPayRequest",
                {
                  //appId: "wxaca9d08ae096883e",
                  appId: "wx7f4b3784986dcb47",
                  timeStamp: res.data.data.timeStamp,
                  nonceStr: res.data.data.nonce_str,
                  package: "prepay_id=" + res.data.data.prepay_id,
                  signType: "MD5",
                  paySign: res.data.data.paySign,
                },
                function (res) {
                  if (res.err_msg == "get_brand_wcpay_request:ok") {
                    Router.push({
                      path: "/orderstate",
                    });
                  } else {
                    this.$toast("微信支付失败");
                  }
                }
              );
            } else {
              if (res.data.data.mweb_url) {
                window.location.href = res.data.data.mweb_url;
              }
            }
          } else {
            this.alipayfrom = res.data.data.aliSign;
            this.$nextTick(() => {
              document.forms["punchout_form"].submit();
            });
          }
        }
      });
    },
    changTab(val) {
      this.orderList();
      this.page = 1;
    },
    orderList() {
      let formData = new FormData();
      //   formData.append("page", Number(this.page));
      formData.append("type", this.active);
      orderList(formData).then((res) => {
        this.list = res.data.data;
      });
    },
    gopath(path, val) {
      if (path == "back") {
        this.$router.go(-1);
      } else if (path == "/orderinfo") {
        console.log(val);
        this.$router.push({
          path: path,
          query: { orderId: val.orderId, orderType: val.orderType },
        });
      } else {
        this.$router.replace({
          path: "/orderstate",
          query: {
            serviceId: window.sessionStorage.getItem("orderId"),
            serviceType: window.sessionStorage.getItem("orderType"),
          },
        });
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // vm相当于上面的this
      if (from.path == "/" && sessionStorage.getItem("payFlag") == "1") {
        vm.gopath("/orderstate");
      }
      vm.fromPage = from.path;
    });
  },
};
</script>

<style lang="less" scoped>
.header {
  padding: 10px 10px;
  display: flex;
  .left {
    width: 10%;
    .icon {
      font-size: 24px;
      font-weight: 600;
      color: #141414;
    }
  }
  .title {
    width: 85%;
    text-align: center;
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141414;
  }
}
.list {
  padding: 0 14px;
  .item {
    padding: 14px 0 14px 0;
    border-bottom: 1px solid #ddd;
    .top {
      display: flex;
      .top_left {
        width: 37%;
        .left_img {
          width: 100%;
        }
      }
      .top_right {
        width: 63%;
        margin-left: 10px;
        .right_title {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 550;
          color: #333333;
          line-height: 20px;
          margin-bottom: 14px;
          overflow: hidden; //溢出隐藏
          text-overflow: ellipsis; //超出省略号显示
          white-space: nowrap; //文字不换行
        }
        .term {
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 18px;
        }
      }
    }
    .center {
      text-align: right;
      .total {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        margin-right: 10px;
      }
      .actually {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 11px;
      .yell_text {
        color: #fd8448;
        border: 1px solid #fd8448;
        padding: 2px;
        border-radius: 50%;
      }
      .text_p {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
      }
      .bottom_left {
        width: 48%;
      }
      .bottom_right {
        .cancal {
        }
        .payBtn {
          height: 34px !important;
          border-radius: 5px !important; /*no*/
          min-width: 85px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 20px;
          background: #5f7dff;
          color: #fff;
        }
        .van-button {
          height: 34px !important;
          border-radius: 5px !important; /*no*/
          min-width: 85px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 20px;
          &:first-child {
            margin-right: 6px;
          }
        }
      }
    }
  }
}
/deep/ .van-tabs__line {
  background: #5f7dff;
}
/deep/ .van-tab {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  &:first-child {
    .van-tab__text--ellipsis {
      border-left: none;
    }
  }
}
/deep/ .van-tab--active .van-tab__text--ellipsis {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 23px;
}
/deep/ .van-tab__text--ellipsis {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  border-left: 1px solid #ddd;
  line-height: 23px;
  width: 100%;
  text-align: center;
}
.alipaysubmit {
  display: none;
}
</style>


